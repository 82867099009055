import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./style/DetailKomik.css";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../contexts/UserContext";
import Login from "../components/Login";
import axios from "axios";
import { getAuth, onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { serverTimestamp } from "firebase/firestore";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const firebaseConfig = require("../firebaseConfig.js");
const { initializeApp } = require("firebase/app");
const {
  getFirestore,
  collection,
  where,
  query,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  runTransaction,
  setDoc,
} = require("firebase/firestore");

// inisiasi secara global
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const DetailKomik = () => {
  const { path } = useParams(); // Mengambil id komik dari URL
  const [comicDetail, setComicDetail] = useState(null);
  const navigate = useNavigate();
  const [showFullSinopsis, setShowFullSinopsis] = useState(false);
  const [loading, setLoading] = useState(true);

  // check user
  const { user, setUser } = useContext(UserContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showData, setShowData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFileId] = useState([]);
  const [message, setMessage] = useState("");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleToggleSinopsis = () => {
    setShowFullSinopsis(!showFullSinopsis);
  };

  useEffect(() => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const db = getFirestore();

    // Membuat query untuk mencari dokumen dengan nilai "guard-pass" pada kunci "path"
    const q = query(collection(db, "komik_detail"), where("path", "==", path));

    // Melakukan query
    getDocs(q)
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          console.log("No documents found matching the query.");
          // Menampilkan pesan bahwa tidak ada detail komik yang ditemukan
        } else {
          const data = querySnapshot.docs.map((doc) => doc.data());
          setComicDetail(data[0]); // Menggunakan data dari dokumen pertama yang ditemukan
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error getting documents: ", err);
        setLoading(false);
      });
  }, [path]); // Memastikan useEffect dipanggil kembali ketika path berubah

  // Fungsi untuk menavigasi ke halaman baca komik
  const handleReadChapter = (chapterTitle) => {
    const title = comicDetail.path;
    // console.log("title", title);

    // Mengubah chapterTitle menjadi huruf kecil dan mengganti spasi atau karakter spesial dengan garis penghubung
    const formattedChapterTitle = chapterTitle
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-+|-+$/g, ""); // Menghapus garis penghubung di awal atau akhir string

    // console.log("formattedChapterTitle", formattedChapterTitle);

    navigate(`/${title}/${formattedChapterTitle}`);
  };

  const handleClick = (genre) => {
    // console.log(`Genre clicked: ${genre}`);
    navigate(`/genre/${genre}`);
  };

  const handleDownloadChapter = async (title, chapter) => {
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    if (!user) {
      console.error("User is not logged in");
      setModalOpen(true);
      return;
    }

    // ambil token
    const authData = JSON.parse(localStorage.getItem("authData"));
    const idToken = await authData.token;
    // console.log("idToken", idToken);

    const sanitizedChapter = chapter.toLowerCase().replace(/[^a-z0-9]+/g, "-");
    const path = `${title}-${sanitizedChapter}`;
    // console.log(title, sanitizedChapter, path);

    // Cek keberadaan file
    const fileQuery = query(
      collection(db, "pixeldrain_pdf"),
      where("path", "==", path)
    );

    const fileSnapshot = await getDocs(fileQuery);
    // console.log("fileSnapshot", fileSnapshot);

    if (fileSnapshot.empty) {
      console.log(`No document found for path: ${path}`);
      setSnackbarSeverity("error");
      setSnackbarMessage("Terjadi kesalahan atau anda telah mencapai limit");
      setOpenSnackbar(true);
      return;
    }

    let fileId = null;
    let filePath = null;

    fileSnapshot.forEach((doc) => {
      fileId = doc.data().id;
      filePath = doc.data().path;
      // console.log("filePath:", filePath);
    });

    // console.log("File ID:", fileId);

    // Unduh file dari backend
    try {
      const baseUrl =
        window.location.hostname === "localhost"
          ? "http://127.0.0.1:5001/backend-bbk/us-central1/api/download/"
          : "https://api-z2ljrxmata-uc.a.run.app/download/";

      const apiDownload = `${baseUrl}${fileId}`;
      // console.log("apiDownload", apiDownload);
      setSnackbarSeverity("info");
      setSnackbarMessage("Pengecekan limit dan proses download");
      setOpenSnackbar(true);

      const response = await axios({
        url: apiDownload,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        validateStatus: (status) => status < 500, // Resolve only if the status code is less than 500
      });

      if (response.status === 404) {
        setSnackbarSeverity("error");
        setSnackbarMessage("File not found.");
        setOpenSnackbar(true);
        return;
      }

      if (response.status !== 200) {
        setSnackbarSeverity("error");
        setSnackbarMessage("Error downloading file, limit anda sudah habis.");
        setOpenSnackbar(true);
        return;
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filePath}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      setSnackbarSeverity("success");
      setSnackbarMessage("Komik telah selesai di unduh");
      setOpenSnackbar(true);
    } catch (downloadError) {
      console.error("Error downloading file:", downloadError);
      setSnackbarSeverity("warning");
      setSnackbarMessage("Terjadi kesalahan atau anda telah mencapai limit");
      setOpenSnackbar(true);
    }
  };

  const handleFavorite = () => {
    if (user) {
      // console.log("Button clicked by user:", user);
    } else {
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleTermsClick = () => {
    // Implementasi untuk membuka halaman Terms and Condition
    // console.log("Terms and Condition clicked");
  };

  if (loading) {
    return (
      <div className="loading-container flex justify-center items-center h-64">
        <div className="flex items-center space-x-4 bg-gray-200 text-gray-800 font-semibold py-3 px-6 rounded-lg shadow-lg">
          <svg
            className="animate-spin h-5 w-5 text-blue-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
            ></path>
          </svg>
          <span>Sedang mendapatkan data...</span>
        </div>
      </div>
    );
  }

  return (
    <HelmetProvider>
      {comicDetail && ( // Conditionally render Helmet content
        <Helmet>
          <title>{comicDetail.title} - BebasBacaKomik.com</title>
          <meta
            name="description"
            content={`Download komik ${comicDetail.title} serta chapter lengkap dan terbaru`}
          />
          <meta
            name="keywords"
            content={`${
              comicDetail.title
            }, baca komik online, ${comicDetail.genres.join(", ")}`}
          />
          <meta
            property="og:title"
            content={`${comicDetail.title} - Baca Komik Online`}
          />
          <meta property="og:description" content={comicDetail.sinopsis} />
          <meta property="og:image" content={comicDetail.coverLink} />
          <script type="application/ld+json">
            {`{
          "@context": "https://schema.org",
          "@type": "ComicStory",
          "name": "${comicDetail.title}",
          "description": "${comicDetail.sinopsis}",
          "image": "${comicDetail.coverLink}",
          "author": {
            "@type": "Person",
            "name": "${comicDetail.author || "Unknown"}"
          },
          "genre": ${JSON.stringify(comicDetail.genres || [])}
        }`}
          </script>
        </Helmet>
      )}
      <div>
        <Header />
        <div className="comic-detail-container">
          {comicDetail && (
            <div className="cover-image-container">
              <img
                src={comicDetail.coverLink}
                alt={comicDetail.title}
                className="cover-image"
              />
            </div>
          )}

          {comicDetail && (
            <div className="button-container">
              <button
                className="read-button bg-blue-500 text-white font-bold py-2 px-4 rounded-lg shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300"
                onClick={() =>
                  handleReadChapter(
                    comicDetail.chapters[comicDetail.chapters.length - 1].title
                  )
                }
              >
                Mulai Baca
              </button>

              {/* <button
                className={`favorite-button text-white font-bold py-2 px-4 rounded-lg shadow-lg transition duration-300 ${
                  user
                    ? "bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                    : "bg-gray-500 cursor-not-allowed"
                }`}
                onClick={handleFavorite}
              >
                Favorite!
              </button> */}
            </div>
          )}

          {modalOpen && (
            <div className="fixed inset-0 flex items-center justify-center z-10 bg-black bg-opacity-50">
              <div className="bg-white p-4 rounded-lg shadow-lg max-w-md relative">
                <button
                  className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 bg-gray-200 hover:bg-gray-300 rounded-full p-1 transition duration-300 ease-in-out"
                  onClick={() => setModalOpen(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                {/* modal login by google */}

                <div className="flex flex-col items-center mt-8">
                  <h2 className="text-1xl mb-4 text-center">
                    Silahkan Mendaftar / Login untuk mengunduh komik GRATIS!
                  </h2>

                  <div className="flex flex-col space-y-4">
                    <button
                      onClick={() => navigate("/register")}
                      className="w-64 px-6 py-3 bg-gradient-to-r from-blue-400 to-purple-600 text-white text-lg font-semibold rounded-lg shadow-md hover:from-blue-500 hover:to-purple-700 transition-colors duration-300"
                    >
                      Register
                    </button>
                    <button
                      onClick={() => navigate("/login")}
                      className="w-64 px-6 py-3 bg-gradient-to-r from-green-400 to-teal-600 text-white text-lg font-semibold rounded-lg shadow-md hover:from-green-500 hover:to-teal-700 transition-colors duration-300"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="comic-detail-info">
            {comicDetail && (
              <div>
                <div className="container-h1">
                  <h1 className="text-4xl font-bold text-center text-gray-800 mt-6 mb-4 shadow-sm">
                    {comicDetail.title}
                  </h1>
                </div>
                <div
                  className={`detail-item-status ${
                    comicDetail.status === "Ongoing"
                      ? "status-ongoing"
                      : comicDetail.status === "Complete"
                      ? "status-complete"
                      : "status-unknown"
                  }`}
                >
                  {comicDetail.status}
                </div>

                <div className="detail-item-genre">
                  {comicDetail.genres &&
                    comicDetail.genres.length > 0 &&
                    comicDetail.genres.map((genre, index) => (
                      <button
                        key={index}
                        className="genre-button"
                        onClick={() => handleClick(genre)}
                      >
                        {genre}
                      </button>
                    ))}
                </div>

                <div className="sinopsis-detail bg-gray-100 p-4 rounded-lg shadow-inner">
                  {comicDetail.sinopsis.length > 150 && !showFullSinopsis ? (
                    <>
                      {comicDetail.sinopsis.slice(0, 150)} ... &nbsp;
                      <button
                        onClick={handleToggleSinopsis}
                        className="read-more text-blue-500 hover:text-blue-700 transition duration-300"
                      >
                        <span className="read-more-text">
                          &nbsp;Baca Selengkapnya
                        </span>
                      </button>
                    </>
                  ) : (
                    comicDetail.sinopsis
                  )}
                </div>
              </div>
            )}
          </div>

          {/* <div className="blank-space"></div> */}

          <div className="comic-detail-chapters">
            {comicDetail && (
              <div>
                <div className="chapter-section">
                  <strong className="text-2xl font-semibold text-gray-800 border-b-2 border-gray-300 pb-1 block mt-4 mb-4">
                    Chapter :
                  </strong>
                  <div className="chapter-list">
                    <ul className="chapter-list-ul space-y-2">
                      {comicDetail.chapters
                        .sort((a, b) => {
                          // Fungsi untuk ekstrak angka dari title
                          const extractNumber = (title) => {
                            const match = title.match(/(\d+)/);
                            return match ? parseInt(match[0], 10) : 0;
                          };

                          // Fungsi untuk ekstrak teks setelah angka
                          const extractText = (title) => {
                            const match = title.match(/\d+\s*(.*)$/);
                            return match ? match[1] : "";
                          };

                          // Dapatkan angka dan teks dari judul
                          const numberA = extractNumber(a.title);
                          const numberB = extractNumber(b.title);
                          const textA = extractText(a.title);
                          const textB = extractText(b.title);

                          // Urutkan berdasarkan angka terlebih dahulu
                          if (numberA !== numberB) {
                            return numberB - numberA; // Urutkan secara menurun berdasarkan angka
                          }

                          // Jika angka sama, urutkan berdasarkan teks
                          return textA.localeCompare(textB); // Urutkan berdasarkan teks secara abjad
                        })
                        .map((chapter, index) => (
                          <li key={index} className="chapter-list-item">
                            <div className="flex items-center justify-between w-full">
                              <button
                                className="chapter-button bg-gray-200 text-gray-700 font-medium py-2 px-4 rounded-lg shadow-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-300 w-full text-left"
                                onClick={() => handleReadChapter(chapter.title)}
                              >
                                {/* {console.log(chapter.title)} */}
                                {chapter.title}
                              </button>
                              {/* fungsi download */}
                              {/*
                              <button
                                className="download-button bg-transparent text-gray-700 ml-2 focus:outline-none transition duration-300"
                                onClick={() =>
                                  handleDownloadChapter(
                                    comicDetail.path,
                                    chapter.title
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faDownload} size="lg" />
                              </button>
                              */}
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={2000}
            onClose={handleSnackbarClose}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleSnackbarClose}
              severity={snackbarSeverity}
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>

          {/* <div className="blank-space"></div> */}
        </div>
      </div>
    </HelmetProvider>
  );
};

export default DetailKomik;
