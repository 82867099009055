import React from "react";
import { useNavigate } from "react-router-dom";

const Genre = () => {
  const navigate = useNavigate();
  const array = [
    "4-Koma",
    "Action",
    "Adaptation",
    "Adult",
    "Adventure",
    "Anthology",
    "Bully",
    "Comedy",
    "Comedy",
    "Cooking",
    "Crime",
    "Crossdressing",
    "Dark Fantasy",
    "Delinquent",
    "Delinquents",
    "Demon",
    "Demons",
    "Doujinshi",
    "Drama",
    "Ecchi",
    "Fantasy",
    "Full Color",
    "Game",
    "Games",
    "Gang",
    "Gender Bender",
    "Genderswap",
    "Ghosts",
    "Girls' Love",
    "Gyaru",
    "Harem",
    "Hero",
    "Historical",
    "Horror",
    "Incest",
    "Isekai",
    "Josei",
    "Leveling",
    "Loli",
    "Lolicon",
    "Long Strip",
    "Mafia",
    "Magi",
    "Magic",
    "Magical Girls",
    "Manga",
    "Manhua",
    "Manhwa",
    "Martial Art",
    "Martial Arts",
    "Mature",
    "Mecha",
    "Medical",
    "Military",
    "Mirror",
    "Modern",
    "Monster Girls",
    "Monsters",
    "Murim",
    "Music",
    "Mystery",
    "Necromancer",
    "Official Colored",
    "One-Shot",
    "Oneshot",
    "Overpowered",
    "Parody",
    "Pets",
    "Philosophical",
    "Police",
    "Post-Apocalyptic",
    "Project",
    "Psychological",
    "Regression",
    "Reincarnation",
    "Revenge",
    "Romance",
    "School",
    "School Life",
    "Sci-fi",
    "Seinen",
    "Seinin",
    "Sexual Violence",
    "Shotacon",
    "Shoujo",
    "Shoujo Ai",
    "Shounen",
    "Shounen Ai",
    "Shounn",
    "Slice of Life",
    "Smut",
    "Sports",
    "Super Power",
    "Superhero",
    "Supernatural",
    "Supranatural",
    "Survival",
    "System",
    "Thriller",
    "Time Travel",
    "Tragedy",
    "Vampire",
    "Vampires",
    "Video Games",
    "Villainess",
    "Violence",
    "Virtual Reality",
    "Web Comic",
    "Webtoons",
    "Wuxia",
    "Yaoi",
    "Yuri",
    "Zombies",
    "gore",
    "gorre"
  ];

  const handleClick = (genre) => {
    // console.log(`Genre clicked: ${genre}`);
    navigate(`/genre/${genre}`);
  };

  return (
    <div className="flex overflow-x-auto">
      {array.map((item, index) => (
        <div
          key={index}
className="list-item cursor-pointer whitespace-nowrap rounded-full px-4 py-2 mr-2 bg-gradient-to-r from-blue-800 to-blue-600 text-white shadow-sm hover:shadow-md transform hover:scale-105 transition duration-300 ease-in-out"
          onClick={() => handleClick(item)}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default Genre;
