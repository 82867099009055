import React, { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { signOut } from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSignOutAlt } from "@fortawesome/free-solid-svg-icons"; // Menambahkan icon logout
import Footer from "../components/Footer";

const Profile = () => {
  const { user, isLoading } = useContext(UserContext);
  const navigate = useNavigate();
  const { refreshUser } = useContext(UserContext);



  return (
    <>
      <Header />

      <p>Halaman ini sedang dikembangkan</p>
      <p>kembali lagi nanti</p>
    </>
  );
};

export default Profile;
