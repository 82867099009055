import React from "react";
import Header from "../components/Header";
import Footer from '../components/Footer';



const TOS = () => {
  return (
    
    <>
        <Header />

      <div className="text-center">
        <h2 className="text-2xl font-bold mb-4">Terms and Conditions</h2>
        <div className="text-left">
          <p className="text-justify mb-4">
            Welcome to Gratis BacaKomik.com. These Terms and Conditions apply to
            your use of our unofficial comic reading application, hereinafter
            referred to as "the App." By accessing or using the App, you agree
            to be bound by these Terms and Conditions. If you do not agree with
            any part of these Terms and Conditions, you must not use the App.
          </p>

          <h2 className="font-bold ">1. Content Disclaimer</h2>
          <p className="text-justify mb-4">
            BebasBacaKomik.com is an unofficial application for reading comics.
            We do not create, modify, or control the content provided through
            the App. The content is sourced from images available on the
            internet, and we do not assume responsibility for the accuracy,
            completeness, or legality of the content.
          </p>

          <h2 className="font-bold">2. Age Restriction</h2>
          <p className="text-justify mb-4">
            Users of BebasBacaKomik.com must be 18 years of age or older. By
            using the App, you confirm that you meet this age requirement and
            acknowledge that you are responsible for all activities conducted
            through your account.
          </p>

          <h2 className="font-bold">3. Legal Compliance</h2>
          <p className="text-justify mb-4">
            Users of BebasBacaKomik.com are expected to comply with all
            applicable laws and regulations, including those of Indonesia. Any
            use of the App for illegal purposes is strictly prohibited.
          </p>

          <h2 className="font-bold">4. Modification of Terms</h2>
          <p className="text-justify mb-4">
            BebasBacaKomik.com reserves the right to modify, amend, or update
            these Terms and Conditions at any time. Changes will be effective
            immediately upon posting. It is your responsibility to review these
            Terms and Conditions periodically for changes.
          </p>

          <h2 className="font-bold">5. Privacy Policy</h2>
          <p className="text-justify mb-4">
            Our Privacy Policy governs the collection, use, and disclosure of
            your personal information. By using the App, you consent to the
            terms of our Privacy Policy.
          </p>

          <h2 className="font-bold">6. Disclaimer of Warranties</h2>
          <p className="text-justify mb-4">
            The App is provided on an "as-is" and "as-available" basis.
            BebasBacaKomik.com makes no warranties, express or implied,
            regarding the reliability, accuracy, availability, or suitability of
            the App or the content therein.
          </p>

          <h2 className="font-bold">7. Limitation of Liability</h2>
          <p className="text-justify mb-4">
            To the fullest extent permitted by law, BebasBacaKomik.com shall not
            be liable for any indirect, incidental, special, consequential, or
            punitive damages arising out of or related to your use of the App,
            including but not limited to loss of data, loss of profits, or
            business interruption.
          </p>

          <h2 className="font-bold">8. Governing Law</h2>
          <p className="text-justify mb-4">
            These Terms and Conditions shall be governed by and construed in
            accordance with the laws of Indonesia, without regard to its
            conflict of law principles.
          </p>

          <h2 className="font-bold">9. Contact Us</h2>
          <p className="text-justify mb-4">
            If you have any questions about these Terms and Conditions, please
            contact us.
          </p>

          <p className="text-justify">
            By using BebasBacaKomik.com, you acknowledge that you have read,
            understood, and agree to be bound by these Terms and Conditions.
          </p>
        </div>
      </div>
      <Footer />

    </>
  );
};

export default TOS;
