// pages/DaftarKomik.js
import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
const firebaseConfig = require("../firebaseConfig");
const { initializeApp } = require("firebase/app");
const { getFirestore, collection, getDocs } = require("firebase/firestore");

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore();
const colRef = collection(db, 'komik_detail');

const DaftarKomik = () => {
    const [comicData, setComicData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const db = getFirestore();
            const colRef = collection(db, 'komik_detail');
            try {
                const snapshot = await getDocs(colRef);
                let comic = [];
                snapshot.forEach((doc) => {
                    comic.push({ ...doc.data(), id: doc.id });
                });
                setComicData(comic);
            } catch (error) {
                console.error("Error reading documents:", error);
            }
        };
        fetchData();
    }, []);

    return (
        <>
        <Header />
            <div>
                <h2>Halaman Populer</h2>
                <div>
                    {/* Tampilkan judulKomik dan linkCover di sini */}
                    {comicData.map((comic, index) => (
                        <div key={comic.id} style={{ position: 'relative', display: 'inline-block', marginRight: '20px', marginBottom: '20px' }}>
                            <img src={comic.linkCover} alt={comic.judulKomik} style={{ width: '200px', height: '300px', borderRadius: '10px', filter: 'brightness(110%) contrast(120%)', imageRendering: 'auto' }} />
                            <h4 style={{ position: 'absolute', top: '5px', left: '5px', color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '5px' }}>{comic.jenisKomik}</h4>
                            <h3 style={{ marginBottom: '0px' }}>{comic.judulKomik.length > 25 ? `${comic.judulKomik.slice(0, 25)}...` : comic.judulKomik}</h3>
                            {comic.chapterKomik.length > 0 && (
                                <div style={{ marginTop: '0', marginBottom: '5px' }}>{comic.chapterKomik[0].text}</div>
                            )}
                        </div>
                    ))}

                </div>
            </div>
        </>
    );
}

export default DaftarKomik;
