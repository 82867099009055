import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const { refreshUser } = useContext(UserContext);
  const [error, setError] = useState(""); // Tambahkan state error

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem("authData"));
    if (authData && authData.token) {
      // conditional for env
      const baseUrl =
        window.location.hostname === "localhost"
          ? process.env.REACT_APP_DEVELOPMENT_URL + "/auth/verifyToken/"
          : process.env.REACT_APP_PRODUCTION_URL + "/auth/verifyToken/";

      axios
        .get(`${baseUrl}`, {
          headers: {
            Authorization: `Bearer ${authData.token}`,
          },
        })
        .then((response) => {
          if (response.data.valid) {
            setIsLoggedIn(true);
            navigate("/profile");
          } else {
            localStorage.removeItem("authData");
          }
        })
        .catch((error) => {
          console.error("Token verification error:", error);
          localStorage.removeItem("authData");
        });
    }
  }, [navigate]);

  useEffect(() => {
    if (email && password) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [email, password]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "email") {
      setEmail(value);
    } else if (id === "password") {
      setPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const baseUrl =
        window.location.hostname === "localhost"
          ? process.env.REACT_APP_DEVELOPMENT_URL + "/auth/login/"
          : process.env.REACT_APP_PRODUCTION_URL + "/auth/login/";

      const response = await axios.post(`${baseUrl}`, {
        email,
        password,
      });

      const { token: authToken, email: userEmail } = response.data;
      localStorage.setItem(
        "authData",
        JSON.stringify({ token: authToken, email: userEmail })
      );
      setIsLoggedIn(true);
      refreshUser(); // Refresh user data before redirect
      navigate("/profile");
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      console.error("Login error:", errorMessage);
      setError(errorMessage); // Set error message to state
    }
  };

  return (
    <>
      <Header />
      <div className="min-h-screen flex items-start justify-center bg-gray-100 pt-2">
        <div className="bg-white p-8 rounded shadow-md w-96">
          <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                placeholder="Masukkan email"
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                placeholder="Masukkan password"
                required
              />
            </div>
            <button
              type="submit"
              disabled={isDisabled}
              className={`w-full py-2 px-4 rounded ${
                isDisabled
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              } text-white`}
            >
              Login
            </button>
            {error && <p className="error-message">{error}</p>}{" "}
            {/* Tampilkan pesan kesalahan */}
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
