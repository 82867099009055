// contexts/UserContext.js
import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  // console.log("userContext", user);
  const [isLoading, setIsLoading] = useState(true);

  const refreshUser = () => {
    setIsLoading(true);
    const authData = JSON.parse(localStorage.getItem("authData"));
    if (authData && authData.token) {
      const baseUrl =
        window.location.hostname === "localhost"
          ? process.env.REACT_APP_DEVELOPMENT_URL + "/auth/verifyToken/"
          : process.env.REACT_APP_PRODUCTION_URL + "/auth/verifyToken/";

      axios
        .get(`${baseUrl}`, {
          headers: {
            Authorization: `Bearer ${authData.token}`,
          },
        })
        .then((response) => {
          console.log("response", response);
          if (response.data.valid) {
            console.log("authData", authData);
            setUser({ email: response.data.email, limit: response.data.limit, name: response.data.name, limit:response.data.limit });
          } else {
            localStorage.removeItem("authData");
            setUser(null);
          }
        })
        .catch((error) => {
          console.error("Token verification error:", error);
          localStorage.removeItem("authData");
          setUser(null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setUser(null);
    }
  };

  useEffect(() => {
    refreshUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, isLoading, refreshUser }}>
      {children}
    </UserContext.Provider>
  );
};
