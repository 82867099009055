// Login.jsx
import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import firebaseConfig from "../firebaseConfig";

// Inisialisasi Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const Login = ({ disabled, onLoginSuccess }) => {
  const handleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const idToken = await result.user.getIdToken();

      const baseUrl =
      window.location.hostname === "localhost"
          ? process.env.REACT_APP_DEVELOPMENT_URL + "/auth/login/"
          : process.env.REACT_APP_PRODUCTION_URL + "/auth/login/";

      // Kirim idToken ke backend
        await fetch(`${baseUrl}`, {

        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ idToken })
      });
      // Panggil onLoginSuccess dengan data pengguna setelah berhasil login
      onLoginSuccess(result.user);
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  return (
    <div>
      <button
        onClick={handleLogin}
        disabled={disabled} // Disesuaikan dengan prop disabled dari parent
        className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded ${
          disabled ? "cursor-not-allowed opacity-50" : ""
        }`}
      >
        Login with Google
      </button>
    </div>
  );
};

export default Login;
