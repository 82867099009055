import React, { Component } from 'react';
import './style/About.css'; // Assuming you have some styles for this component
import Header from '../components/Header';
import Footer from '../components/Footer';

class About extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="about-container mx-auto max-w-3xl py-8 px-4 bg-white rounded-lg shadow-lg">
          <h1 className="text-4xl font-bold text-center mb-6 text-indigo-600">
            About Me
          </h1>
          <p className="text-lg leading-relaxed mb-4 text-gray-700">
            Di <strong className="font-bold text-indigo-600">bebasbacakomik.com</strong>, kami percaya bahwa setiap orang berhak untuk menjelajahi dunia fantasi yang luas tanpa batas. Visi kami adalah dunia yang bebas, di mana imajinasi tidak terkurung oleh dinding-dinding pembatas. Kami berkomitmen untuk menghadirkan pengalaman membaca komik yang tak terlupakan untuk semua orang.
          </p>
          <p className="text-lg leading-relaxed mb-4 text-gray-700">
            Dunia yang kami impikan adalah dunia yang dapat dimiliki oleh semua orang, di mana semua orang dapat terhubung melalui kisah-kisah yang menyentuh hati, menginspirasi, dan membawa kebahagiaan. Kami ingin membangun komunitas yang kuat, di mana setiap orang merasa diterima dan dihargai.
          </p>
          <p className="text-lg leading-relaxed mb-4 text-gray-700">
            Mari bersama-sama menjelajahi dunia tanpa batas, tempat di mana setiap halaman komik membuka pintu ke petualangan baru, tempat di mana setiap cerita dapat menjadi milik siapa saja. Di <strong className="font-bold text-indigo-600">bebasbacakomik.com</strong>, kami adalah bagian dari sesuatu yang lebih besar - sebuah gerakan untuk dunia yang bebas dan penuh dengan kebahagiaan.
          </p>
          <p className="text-lg leading-relaxed mb-4 text-gray-700">
            Bersama <strong className="font-bold text-indigo-600">bebasbacakomik.com</strong>, mari kita wujudkan dunia yang lebih baik, satu halaman komik pada satu waktu. Dunia bebas adalah milik kita semua.
          </p>
        </div>
        <Footer />
      </>
    );
  }
}

export default About;
