// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate  } from "react-router-dom";
import DetailKomik from "./pages/DetailKomik";
import BacaKomik from "./pages/BacaKomik";
import Favorite from "./pages/Populer";
import About from "./pages/About";
import Home from "./pages/Home";
import GenreKomik from "./pages/GenreKomik";
import TypeKomik from "./pages/TypeKomik";
import PencarianKomik from "./pages/PencarianKomik";
import { UserProvider } from "./contexts/UserContext"; // Pastikan casing benar
import Profile from "./pages/Profile";
import NotFound from "./pages/NotFound";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import TOS from "./pages/TOS";
import KoleksiTerbaru from "./pages/KoleksiTerbaru";
import KoleksiTerupdate from "./pages/KoleksiTerupdate";

function App() {
  return (
    <UserProvider>
      <Router>
        <div className="container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/populer" element={<Favorite />} />
            <Route path="/about" element={<About />} />
            <Route path="/pencarian" element={<PencarianKomik />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="/:path" element={<DetailKomik />} />
            <Route path="/:judulKomik/:nomorChapter" element={<BacaKomik />} />
            <Route path="/genre/:selectedGenre" element={<GenreKomik />} />
            <Route path="/tipe/:selectedType" element={<TypeKomik />} />
            <Route path="/koleksi-terbaru" element={<KoleksiTerbaru />} />
            <Route path="/koleksi-terupdate" element={<KoleksiTerupdate />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/TOS" element={<TOS />} />


            {/* Redirect from "/download" to "/detail" */}
            <Route
              path="/download"
              element={<Navigate to="/pencarian" replace />}
            />
          </Routes>
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;
