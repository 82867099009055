import React from 'react';
import { useNavigate } from 'react-router-dom';
import MangaImage from '../assets/Manga.webp';
import ManhuaImage from '../assets/Manhua.webp';
import ManhwaImage from '../assets/Manhwa.webp';

const Tipe = (props) => {
  const navigate = useNavigate();

  const { imageUrl, altText, width, height, margin } = props;

  const handleClick = () => {
    navigate(`/tipe/${altText}`);
  };

  return (
    <div style={{ margin: margin }}>
      <img
        src={imageUrl}
        alt={altText}
        width={width}
        height={height}
        style={{
          marginRight: '10px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          marginTop: '20px',
          cursor: 'pointer',
          display: 'block'
        }}
        onClick={handleClick}
      />
    </div>
  );
};

const App = () => {
  return (
    <div className="flex justify-center">
      <div className="text-center mx-4">
        <Tipe imageUrl={MangaImage} altText="Manga" width={60} height={60} margin="0" />
        <div className="mt-2 text-lg font-semibold">Manga</div>
      </div>
      <div className="text-center mx-4">
        <Tipe imageUrl={ManhuaImage} altText="Manhua" width={60} height={60} margin="0" />
        <div className="mt-2 text-lg font-semibold">Manhua</div>
      </div>
      <div className="text-center mx-4">
        <Tipe imageUrl={ManhwaImage} altText="Manhwa" width={60} height={60} margin="0" />
        <div className="mt-2 text-lg font-semibold">Manhwa</div>
      </div>
    </div>
  );
};

export default App;
