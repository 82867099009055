import React, { useState, useCallback } from "react";
import "./style/PencarianKomik.css"; // Pastikan Anda mengimpor file CSS
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async"; // Impor Helmet
import Footer from "../components/Footer";
import _ from "lodash";

const firebaseConfig = require("../firebaseConfig");
const { initializeApp } = require("firebase/app");
const { getFirestore } = require("firebase/firestore");

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const PencarianKomik = () => {
  const [queryText, setQueryText] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleComicSelection = (comic) => {
    navigate(`/${comic.path}`);
  };

  const fetchSearchResults = useCallback(
    _.debounce(async (value) => {
      setIsLoading(true);
      try {
        const baseUrl =
          window.location.hostname === "localhost"
            ? process.env.REACT_APP_DEVELOPMENT_URL
            : process.env.REACT_APP_PRODUCTION_URL;

        const response = await fetch(`${baseUrl}/public/search?q=${value}`);
        const data = await response.json();
        setFilteredItems(data);
      } catch (error) {
        console.error("Error:", error);
        setFilteredItems([]);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    []
  );

  const handleSearch = (event) => {
    // const value = event.target.value.toLowerCase();
    const value = event.target.value;
    // console.log("value", value);

    setQueryText(value);

    if (value.length > 2) {
      fetchSearchResults(value);
    } else {
      setFilteredItems([]);
      setIsLoading(false);
    }
  };

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Cari & Download Komik Lengkap - BebasBacaKomik.com</title>
          <meta
            name="description"
            content="Temukan dan download komik favoritmu dari seluruh dunia di BebasBacaKomik.com! Koleksi lengkap, update terbaru, berbagai genre & bahasa."
          />
          <meta
            name="keywords"
            content="download komik, baca komik online, komik pdf, download manga, download manhwa, download komik webtoon, komik indonesia, komik terbaru"
          />
          <link
            rel="canonical"
            href="https://www.bebasbacakomik.com/pencarian"
          />
          <meta
            property="og:title"
            content="Cari & Download Komik Lengkap - BebasBacaKomik.com"
          />
          <meta
            property="og:description"
            content="Temukan dan download komik favoritmu dari seluruh dunia di BebasBacaKomik.com! Koleksi lengkap, update terbaru, berbagai genre & bahasa."
          />
          <meta
            property="og:image"
            content="https://www.bebasbacakomik.com/logo512.png"
          />
          <meta
            property="og:url"
            content="https://www.bebasbacakomik.com/pencarian"
          />
        </Helmet>
        <Header />
        <div className="search-container mt-4">
          <div className="search-box">
            <input
              type="text"
              value={queryText}
              onChange={handleSearch}
              placeholder="Ketik judul ..."
              className="input-cari border-2 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 rounded-md p-2"
            />
            {isLoading ? (
              <div className="loading-container flex justify-center items-center h-20">
                <div className="flex items-center space-x-4 bg-gray-200 text-gray-800 font-semibold py-3 px-6 rounded-lg shadow-lg">
                  <svg
                    className="animate-spin h-5 w-5 text-blue-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                    ></path>
                  </svg>
                  <span className="text-sm -mt-4">
                    Segera menampilkan daftar...
                  </span>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-3 gap-4 p-4">
                {filteredItems.map((item) => (
                  <div
                    key={item.id}
                    className="flex flex-col items-center bg-white rounded-lg shadow-lg cursor-pointer hover:shadow-xl transition-shadow duration-300"
                    onClick={() => handleComicSelection(item)}
                  >
                    <img
                      src={item.coverLink}
                      alt={item.title}
                      className="w-24 h-36 object-cover mb-2 rounded-md"
                    />
                    <h3 className="text-sm font-semibold mb-1 truncate w-full">
                      {item.title}
                    </h3>
                    <p className="text-[10px] text-gray-600 text-center">
                      {item.description}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </HelmetProvider>
  );
};

export default PencarianKomik;
