import axios from "axios";
import React, { useState, useContext } from "react";
import Header from "../components/Header";
import { UserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

const modalStyles = {
  content: {
    top: "25vh",
    left: "2vh",
    right: "2vh",
    bottom: "25vh",
    overflow: "auto",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    border: "none",
    background: "#ffffff",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
};

const RegisterPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { refreshUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [showTermsDialog, setShowTermsDialog] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Validasi dan penanganan error
    if (name === "email") {
      if (!validateEmail(value)) {
        setErrors({
          ...errors,
          email: 'Email harus mengandung "@" dan valid',
        });
      } else {
        setErrors({
          ...errors,
          email: "",
        });
      }
    }

    if (name === "password") {
      if (value.length < 6) {
        setErrors({
          ...errors,
          password: "Password minimal 6 karakter",
        });
      } else if (!validatePassword(value)) {
        setErrors({
          ...errors,
          password: "Password harus memiliki angka serta simbol",
        });
      } else {
        setErrors({
          ...errors,
          password: "",
        });
      }
    }

    if (name === "confirmPassword") {
      if (value !== formData.password) {
        setErrors({
          ...errors,
          confirmPassword: "Password tidak sama",
        });
      } else {
        setErrors({
          ...errors,
          confirmPassword: "",
        });
      }
    }
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/;
    return re.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;

    if (!validateEmail(formData.email)) {
      setErrors({
        ...errors,
        email: 'Email harus mengandung "@" dan valid',
      });
      valid = false;
    } else {
      setErrors({
        ...errors,
        email: "",
      });
    }

    if (formData.password.length < 6) {
      setErrors({
        ...errors,
        password: "Password minimal 6 karakter",
      });
      valid = false;
    } else if (!validatePassword(formData.password)) {
      setErrors({
        ...errors,
        password: "Password harus memiliki angka serta simbol",
      });
      valid = false;
    } else {
      setErrors({
        ...errors,
        password: "",
      });
    }

    if (formData.password !== formData.confirmPassword) {
      setErrors({
        ...errors,
        confirmPassword: "Password tidak sama",
      });
      valid = false;
    } else {
      setErrors({
        ...errors,
        confirmPassword: "",
      });
    }

    if (valid) {
      try {
        const baseUrl =
          window.location.hostname === "localhost"
            ? process.env.REACT_APP_DEVELOPMENT_URL + "/auth/register/"
            : process.env.REACT_APP_PRODUCTION_URL + "/auth/register/";

        // URL endpoint backend Anda
        const response = await axios.post(`${baseUrl}`, {
          name: formData.name,
          email: formData.email,
          password: formData.password,
        });

        // console.log("Response from backend:", response.data);
        // Lakukan sesuatu setelah berhasil, seperti redirect atau menunjukkan pesan sukses
        // Misalnya, redirect ke halaman login atau menunjukkan pesan pendaftaran berhasil
        const { token: authToken, email: userEmail } = response.data;
        localStorage.setItem(
          "authData",
          JSON.stringify({ token: authToken, email: userEmail })
        );
        setIsLoggedIn(true);
        refreshUser(); // Refresh user data before redirect
        navigate("/profile");
      } catch (error) {
        if (error.response) {
          // Respon dari server
          const errorMessage = error.response.data.message;
          if (errorMessage === "Email sudah digunakan") {
            // Tampilkan pesan kesalahan kepada pengguna bahwa email sudah ada
            setErrors({
              ...errors,
              email: "Email sudah digunakan",
            });
          } else {
            // Tampilkan pesan kesalahan umum
            console.error("Terjadi kesalahan:", errorMessage);
          }
        } else {
          // Kesalahan yang tidak terkait dengan respon server
          console.error("Terjadi kesalahan:", error.message);
        }
      }
    }
  };

  const isFormInvalid = () => {
    return (
      !formData.name ||
      !validateEmail(formData.email) ||
      formData.password.length < 6 ||
      !validatePassword(formData.password) ||
      formData.password !== formData.confirmPassword ||
      errors.email ||
      errors.password ||
      errors.confirmPassword ||
      !isChecked
    );
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleTermsClick = () => {
    setShowTermsDialog(true);
  };

  const handleAgreeTerms = () => {
    setIsChecked(true);
    setShowTermsDialog(false);
  };

  return (
    <>
      <Header />
      <div className="min-h-screen flex items-start justify-center bg-gray-100 pt-2">
        <div className="bg-white p-8 rounded shadow-md w-96">
          <h2 className="text-2xl font-bold mb-6">Register</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                required
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">{errors.email}</p>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                required
              />
              {errors.password && (
                <p className="text-red-500 text-sm mt-1">{errors.password}</p>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                required
              />
              {errors.confirmPassword && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.confirmPassword}
                </p>
              )}
            </div>
            <div className="flex items-center justify-center mb-4">
              <input
                type="checkbox"
                id="terms"
                className="mr-2"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="terms" className="text-gray-600">
                Setuju dengan{" "}
                <span
                  className="text-blue-500 font-bold cursor-pointer"
                  onClick={handleTermsClick}
                >
                  Terms and Conditions
                </span>
              </label>
            </div>
            <div>
              <button
                type="submit"
                className={`w-full p-2 rounded-md shadow-md ${
                  isFormInvalid()
                    ? "bg-gray-500 cursor-not-allowed"
                    : "bg-blue-500 hover:bg-blue-600"
                } text-white`}
                disabled={isFormInvalid()}
              >
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
      Modal untuk Terms and Conditions
      <Modal
        isOpen={showTermsDialog}
        onRequestClose={() => setShowTermsDialog(false)}
        style={modalStyles}
        contentLabel="Terms and Conditions"
      >
        <div className="text-center p-6">
          <h2 className="text-3xl font-bold mb-4">Terms and Conditions</h2>
          <div className="text-left">
          <p className="text-justify">
            Welcome to Gratis BacaKomik.com. These Terms and Conditions apply to your use of our unofficial comic reading application, 
            hereinafter referred to as "the App." By accessing or using the App, you agree to be bound by these Terms and Conditions. 
            If you do not agree with any part of these Terms and Conditions, you must not use the App.
            </p>

            <h2>1. Content Disclaimer</h2>
            <p className="text-justify">
              BebasBacaKomik.com is an unofficial application for reading
              comics. We do not create, modify, or control the content provided
              through the App. The content is sourced from images available on
              the internet, and we do not assume responsibility for the
              accuracy, completeness, or legality of the content.
            </p>

            <h2>2. Age Restriction</h2>
            <p className="text-justify">
              Users of BebasBacaKomik.com must be 18 years of age or older. By
              using the App, you confirm that you meet this age requirement and
              acknowledge that you are responsible for all activities conducted
              through your account.
            </p>

            <h2>3. Legal Compliance</h2>
            <p className="text-justify">
              Users of BebasBacaKomik.com are expected to comply with all
              applicable laws and regulations, including those of Indonesia. Any
              use of the App for illegal purposes is strictly prohibited.
            </p>

            <h2>4. Modification of Terms</h2>
            <p className="text-justify">
              BebasBacaKomik.com reserves the right to modify, amend, or update
              these Terms and Conditions at any time. Changes will be effective
              immediately upon posting. It is your responsibility to review
              these Terms and Conditions periodically for changes.
            </p>

            <h2>5. Privacy Policy</h2>
            <p className="text-justify">
              Our Privacy Policy governs the collection, use, and disclosure of
              your personal information. By using the App, you consent to the
              terms of our Privacy Policy.
            </p>

            <h2>6. Disclaimer of Warranties</h2>
            <p className="text-justify">
              The App is provided on an "as-is" and "as-available" basis.
              BebasBacaKomik.com makes no warranties, express or implied,
              regarding the reliability, accuracy, availability, or suitability
              of the App or the content therein.
            </p>

            <h2>7. Limitation of Liability</h2>
            <p className="text-justify">
              To the fullest extent permitted by law, BebasBacaKomik.com shall
              not be liable for any indirect, incidental, special,
              consequential, or punitive damages arising out of or related to
              your use of the App, including but not limited to loss of data,
              loss of profits, or business interruption.
            </p>

            <h2>8. Governing Law</h2>
            <p className="text-justify">
              These Terms and Conditions shall be governed by and construed in
              accordance with the laws of Indonesia, without regard to its
              conflict of law principles.
            </p>

            <h2>9. Contact Us</h2>
            <p className="text-justify">
              If you have any questions about these Terms and Conditions, please
              contact us at{" "}
              <a href="mailto:contact@example.com">contact@example.com</a>.
            </p>

            <p className="text-justify">
              By using BebasBacaKomik.com, you acknowledge that you have read,
              understood, and agree to be bound by these Terms and Conditions.
            </p>
          </div>
          <button
            className={`bg-${isChecked ? "orange" : "gray"}-500 hover:bg-${
              isChecked ? "orange" : "gray"
            }-600 text-white font-bold py-2 px-8 rounded mt-4 transition duration-300 ${
              isChecked ? "" : "cursor-not-allowed opacity-50"
            }`}
            onClick={handleAgreeTerms}
            disabled={!isChecked}
          >
            Ya, Saya Setuju
          </button>
        </div>
      </Modal>
    </>
  );
};

export default RegisterPage;
