import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import firebaseConfig from "../firebaseConfig";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const Ongoing = () => {
  const [comicData, setComicData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const navigate = useNavigate();

  const [imageStatus, setImageStatus] = useState({});

  useEffect(() => {
    displayData.forEach((komik) => {
      const img = new Image();
      img.src = komik.imgSrc;

      img.onload = () => {
        setImageStatus((prevStatus) => ({
          ...prevStatus,
          [komik.imgSrc]: true,
        }));
      };

      img.onerror = () => {
        setImageStatus((prevStatus) => ({
          ...prevStatus,
          [komik.imgSrc]: false,
        }));
      };
    });
  }, [displayData]);

  // Fetch data from backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseUrl =
          window.location.hostname === "localhost"
            ? process.env.REACT_APP_DEVELOPMENT_URL
            : process.env.REACT_APP_PRODUCTION_URL;

        const response = await fetch(`${baseUrl}/public/komik_update`);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        // console.log("comic update", data); // Log the actual data
        setComicData(data);
      } catch (error) {
        console.error("Error fetching data from backend:", error);
      }
    };

    fetchData();
  }, []);

  // Update display data based on screen size
  useEffect(() => {
    const updateDisplayData = () => {
      const isDesktop = window.innerWidth >= 768;
      setDisplayData(comicData.slice(0, isDesktop ? 18 : 9));
    };

    updateDisplayData();
    window.addEventListener("resize", updateDisplayData);

    return () => window.removeEventListener("resize", updateDisplayData);
  }, [comicData]);

  // Event handler when an image is clicked
  const handleComicClick = (comic) => {
    navigate(`/${comic}`); // Navigate to the DetailKomik page with the comic path
  };

  return (
    <div className="home">
      <h3 className="flex justify-between items-center text-lg font-semibold text-center py-2 bg-gradient-to-r from-blue-600 via-pink-500 to-blue-400 text-white rounded-md shadow-md hover:shadow-lg transition duration-300 mb-2">
        <span className="ml-2">Koleksi Terupdate</span>
        <span
          className="text-sm font-normal cursor-pointer"
          onClick={() => navigate("/koleksi-terupdate")}
        >
          Semua &nbsp;
        </span>
      </h3>

      <div className="grid grid-cols-3 gap-1 md:grid-cols-6">
        {displayData.map((Ongoing, index) => (
          <div key={index} className="relative flex flex-col items-center">
            <img
              src={
                imageStatus[Ongoing.imgSrc]
                  ? Ongoing.imgSrc
                  : "https://bebasbacakomik.com/logo192.png"
              }
              alt={Ongoing.judul}
              className="cursor-pointer hover:opacity-80 transition duration-300 w-[100px] h-[150px] md:w-[150px] md:h-[200px] rounded-lg"
              onClick={() => handleComicClick(Ongoing.path)}
            />
            <div className="absolute top-1/2 right-2 transform -translate-y-1/2 text-xs md:text-sm text-white bg-black bg-opacity-40 px-5 py-1 rounded">
              {Ongoing.chapter}
            </div>
            <div
              className="text-center mt-2 font-bold text-gray-800 hover:text-indigo-600 transition duration-300 cursor-pointer mb-4"
              onClick={() => handleComicClick(Ongoing.path)}
            >
              {Ongoing.judul && Ongoing.judul.length > 12
                ? `${Ongoing.judul.slice(0, 12)}...`
                : Ongoing.judul}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ongoing;
