import React from 'react';
import Header from '../components/Header';
import { useLocation, Link } from 'react-router-dom';
import { HelmetProvider, Helmet } from "react-helmet-async";

const NotFound = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const judulKomik = queryParams.get('judulKomik');

  return (
    <HelmetProvider>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Header />
      <div className="flex flex-col items-center justify-center min-h-[80vh] bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 text-white p-4 pb-16">
        <div className="text-center">
          <h1 className="text-6xl font-bold mb-4 animate-bounce">404</h1>
          <p className="text-xl mb-4">Oops! Halaman yang kamu cari tidak ditemukan!</p>
          {judulKomik && (
            <>
              {/* <p className="text-lg mb-4">
                Maaf, judul komik <span className="font-bold">{judulKomik}</span> tidak tersedia di sini.
              </p> */}
              <p className="text-lg mb-4">
                Kembali ke halaman Sinopsis : <Link to={`/${judulKomik}`} className="text-blue-300 underline">{judulKomik}</Link>
              </p>
            </>
          )}
        </div>
      </div>
    </HelmetProvider>
  );
};

export default NotFound;
