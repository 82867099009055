import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSignOutAlt } from "@fortawesome/free-solid-svg-icons"; // Menambahkan icon logout
import { auth } from "../connections/Firebase";
import { signOut } from "firebase/auth";
// import { UserContext } from "../contexts/UserContext";
import "./style/Header.css";
import Bebasbacakomik from "../assets/bebasbacakomik-title.png";
import home from "../assets/home.png";
import pencarian from "../assets/pencarian.png";
import library from "../assets/library.png";
import profil from "../assets/library.png";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const { user, setUser } = useContext(UserContext);
  const [activeMenu, setActiveMenu] = useState(location.pathname);

  useEffect(() => {
    setActiveMenu(location.pathname);
  }, [location.pathname]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    // Lakukan proses logout di sini
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Sign out error:", error);
      });
  };

  return (
    <>
      <div>
      <header className="bg-gradient-to-r from-blue-300 via-gold-300 to-red-300 p-1">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 flex items-center justify-between">
            <img
              src={Bebasbacakomik}
              alt="BebasBacaKomik.com"
              className="h-6 sm:h-12 drop-shadow-md sm:mr-4"
            />
          </div>
        </header>
      </div>

      <div className="bg-gradient-to-r from-blue-300 via-gold-300 to-red-300 p-2">
        <div className="flex flex-wrap justify-between">
          <div
            className={`menu-item p-2 text-center ${
              activeMenu === "/" ? "active" : ""
            }`}
            onClick={() => handleNavigation("/")}
          >
            <span className="menu-text">Home</span>
            <img
              src={home}
              className="menu-icon hidden sm:inline-block ml-2 h-6 w-6"
              alt="Home Icon"
            />
          </div>
          <div
            className={`menu-item p-2 text-center ${
              activeMenu === "/pencarian" ? "active" : ""
            }`}
            onClick={() => handleNavigation("/pencarian")}
          >
            <span className="menu-text">Pencarian</span>
            <img
              src={pencarian}
              className="menu-icon hidden sm:inline-block ml-2 h-6 w-6"
              alt="Pencarian Icon"
            />
          </div>

          <div
            className={`menu-item p-2 text-center ${
              activeMenu === "/profile" ? "active" : ""
            }`}
            onClick={() => handleNavigation("/profile")}
          >
            <span className="menu-text">Profil</span>
            <img
              src={profil}
              className="menu-icon hidden sm:inline-block ml-2 h-6 w-6"
              alt="Profil Icon"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
